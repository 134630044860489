<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Макеты" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Профиль-2" transform="translate(-320.000000, -130.000000)" fill="#CF6868">
        <path d="M338.00015,130 L321.99985,130 C320.899732,130 320.010019,130.899732 320.010019,131.99985 L320,150 L323.999699,145.9998 L338.00015,145.9998 C339.099767,145.9998 340,145.100068 340,143.99995 L340,131.99985 C340,130.899732 339.099767,130 338.00015,130 L338.00015,130 Z M328.999825,132.999775 L330.999674,132.999775 L330.999674,138.999825 L328.999825,138.999825 L328.999825,132.999775 Z M328.999825,140.999674 L330.999674,140.999674 L330.999674,142.999524 L328.999825,142.999524 L328.999825,140.999674 Z" id="Fill-14"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "DangerSvg"
}
</script>

<style scoped>

</style>
