<template>
  <div class="item"
       ref="item"
       :class="{'item_disabled' : disabled}"
       v-touch:start="onTapStart"
       v-touch:end="onTapEnd">
    <TariffSvg
        class="item__icon"
        v-if="icon === 'tariff'"
        :color="isNeedPay ? '#CF6868' : null"/>
    <RecordSvg
        class="item__icon"
        v-if="icon === 'record'"
        type="setting"
    />
    <RoomSvg
        class="item__icon"
        v-if="icon === 'room'"/>
    <SettingsSvg
        class="item__icon"
        v-if="icon === 'settings'"/>
    <FaqSvg
        class="item__icon"
        v-if="icon === 'faq'"/>
    <ExitSvg
        class="item__icon"
        v-if="icon === 'exit'"/>
    <p
        class="item__text"
        :class="{'item__text_danger' : icon === 'exit'}">{{text}}</p>
    <DangerSvg
        class="item__danger"
        v-if="isNeedPay"/>
  </div>
</template>

<script>
import DangerSvg from "../../assets/vue-icons/Settings/DangerSvg";
import TariffSvg from "../../assets/vue-icons/Settings/TariffSvg";
import RecordSvg from "../../assets/vue-icons/Settings/RecordSvg";
import RoomSvg from "../../assets/vue-icons/Settings/RoomSvg";
import SettingsSvg from "../../assets/vue-icons/Settings/SettingsSvg";
import FaqSvg from "../../assets/vue-icons/Settings/FaqSvg";
import ExitSvg from "../../assets/vue-icons/Settings/ExitSvg";
export default {
  name: "SettingsListItem",
  props:{
    icon: String,
    text: String,
    isNeedPay: Boolean,
    disabled: Boolean
  },
  components: {ExitSvg, FaqSvg, SettingsSvg, RoomSvg, RecordSvg, TariffSvg, DangerSvg},
  methods:{

    onTapStart(){
      this.$refs.item.style.background = "#fbfbfb"
    },


    onTapEnd(){
      this.$refs.item.style.background = "#ffffff"
    }
  }
}
</script>

<style scoped lang="scss">
.item{
  display: flex;
  width: 500px;
  height: 64px;
  background: #FFFFFF;
  border: 1px solid rgba(198,206,212,0.50);
  border-radius: 6px;
  align-items: center;

  @include media(767){
    width: 345px;
  }

  &__text{
    margin-left: 15px;
    font-family: Roboto, "sans-serif";
    font-weight: 500;
    font-size: 16px;
    color: #333942;
    text-align: left;
    line-height: 19px;
    width: 240px;

    &_danger{
      color: #CF6868;
    }
  }

  &__icon{
    margin-left: 16px;
  }

  &__danger{
    margin-bottom: 4px;
  }

  &_disabled{
    opacity: .5;
    pointer-events: none;
  }

}

</style>
