<template>
  <div class="settings">
    <div class="settings__list">
      <SettingsListItem
          class="settings__list__item"
          icon="tariff"
          text="Управление подпиской"
          @click.native="goToPay"
          :is-need-pay="!$store.getters.PAY_STATUS"
          />
      <SettingsListItem
          class="settings__list__item"
          icon="record"
          text="Смотреть запись"
          @click.native="goToArchive"
          :disabled="!$store.getters.PAY_STATUS"/>
      <SettingsListItem
          class="settings__list__item"
          icon="room"
          text="Родительский кабинет"
          @click.native="goToClasses"
          :disabled="!$store.getters.PAY_STATUS"/>
      <SettingsListItem
          class="settings__list__item"
          icon="settings"
          text="Настройки профиля"
          @click.native="goToProfile"
          />
      <SettingsListItem
          class="settings__list__item"
          icon="faq"
          text="FAQ"
          @click.native="goToFaq"/>
      <SettingsListItem
          class="settings__list__item"
          icon="exit"
          text="Выход"
          @click.native="exit"/>
    </div>
  </div>
</template>

<script>
import BottomNavigationBar from "@/components/NavBar/BottomNavigationBar";
import SettingsListItem from "../../../components/Settings/SettingsListItem";
export default {
  name: "SettingsMainListView",
  components: {
    SettingsListItem, BottomNavigationBar},
  methods:{
    async goToClasses(){
      await this.$router.push({name:"SettingsClassesView"})

    },

    async goToProfile(){
      await this.$router.push({name:"SettingsProfileView"})

    },


    async goToFaq(){
      await this.$router.push({name:"SettingsFaqView"})

    },


    async goToArchive(){
      if (this.$store.getters.PROFILE.myClasses.length > 1){
        await this.$router.push({name:"ArchiveClassList"})
      } else {
        // перемещаем сразу в список архива
        const cl = this.$store.getters.PROFILE.myClasses[0]

        await this.$store.dispatch("parseArchiveBySchoolClass",{
          educationObjectId: cl.educationObjectId,
          schoolClassId: cl.id
        })

        await this.$store.commit("SET_SCHOOL_CLASS_ARCHIVE",cl)
        await this.$router.push({name:"ArchiveListView",params:{classId : cl.id}})
      }
    },

    async goToPay(){
      await this.$router.push({name:"ParentPay"})
    },

    async exit(){
      await this.$store.dispatch("RESET_STATE_TO_DEFAULT")
      await this.$store.dispatch("EXIT_FROM_ACCOUNT");

      await this.$router.push({name:"NewAuth"})
    },
  },
  async mounted() {
    await this.$store.dispatch("UPDATE_LOCAL_PROFILE")
  }

}
</script>

<style scoped lang="scss">

.settings{
  &__list{
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__item{
      margin-top: 20px;

      @include media(767){
        margin-top: 10px;
      }
    }
  }
}


</style>
